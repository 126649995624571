import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import FormSB from '../../../components/Form';
import BlogNews from '../../../components/BlogNews';

export default class FAQ extends Component {
  state = {
    data: [],
  };

  componentDidMount() {
    document.title = 'Site Blindado - FAQ';
  }

  callbackFunction = (data) => {
    this.setState({ data });
  };

  render() {
    return (
      <Layout>
        <div className="padding-container padding-top-mobile bg-dark-green">
          <Container>
            <Row className="h-100 align-items-center">
              <Col xs={12} md={8}>
                <h1 className="font-black mb-4">Diretrizes de privacidade com Site Blindado</h1>
              </Col>
              <Col xs={12} md={4}>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="padding-container">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <p>O Site Blindado segue os padrões, os regulamentos e as melhores
                  práticas do setor. Nossa visão é de que consistência e conformidade
                  em torno de privacidade e proteção de dados orientam as boas práticas
                  empresariais e o bom relacionamento com o Parceiro.
                </p>

                <h4 className="text-dark font-bold mt-5">O QUE É LGPD?</h4>
                <p>LGPD é a sigla utilizada para a Lei Geral de Proteção de Dados Pessoais.
                  A Lei refere-se ao tratamento de dados pessoais para proteção de dados
                  no Brasil, a entrar em vigor.
                </p>

                <h4 className="text-dark font-bold mt-5">Qual é o objetivo da LGPD e a quem ela se destina?</h4>
                <p>A LGPD foi criada com o objetivo de proporcionar ao cidadão brasileiro
                  um controle maior sobre o tratamento de seus dados pessoais. Para isso,
                  a LGPD estabelece princípios e cria regras que devem ser seguidas por
                  qualquer pessoa ou organização, seja privada ou pública, que realize
                  o tratamento de dados pessoais.
                </p>

                <h4>O que são “Controlador” e “Operador” de dados?</h4>
                <p>Segundo a LGPD, o Controlador é qualquer pessoa natural ou jurídica,
                  de direito público ou privado, a quem competem as decisões referentes
                  ao tratamento de dados pessoais. E o Operador é qualquer pessoa natural
                  ou jurídica, de direito público ou privado, que realiza o tratamento
                  de dados pessoais em nome do controlador.
                </p>
                <p>Para o tratamento de dados pessoais dos seus clientes, você será o
                  Controlador e o Site Blindado será o Operador.
                </p>

                <h4 className="text-dark font-bold mt-5">O que são “dados pessoais”?</h4>
                <p>Qualquer informação relacionada a uma pessoa que a identifique ou que,
                  usada em combinação com outras informações tratadas, identifique um
                  indivíduo. Ainda, qualquer informação por meio da qual seja possível
                  identificar uma pessoa ou entrar em contato com ela.
                </p>

                <h4 className="text-dark font-bold mt-5">O que são “dados pessoais sensíveis”?</h4>
                <p>Qualquer dado pessoal, conforme estabelecido na Lei, sobre origem
                  racial ou étnica, convicção religiosa, opinião política,
                  filiação a sindicato ou a organização de caráter religioso, filosófico
                  ou político, dado referente à saúde ou à vida sexual, dado genético
                  ou biométrico, quando vinculado a uma pessoa natural.
                </p>

                <h4 className="text-dark font-bold mt-5">O que é considerado tratamento de dados?</h4>
                <p>Considera-se tratamento de dado pessoal a coleta, produção, recepção,
                  classificação, utilização, acesso, reprodução, transmissão, distribuição,
                  processamento, arquivamento, armazenamento, eliminação, avaliação ou
                  controle da informação, comunicação, transferência, difusão ou extração
                  de dados de pessoas físicas.
                </p>

                <h4 className="text-dark font-bold mt-5">Em casos de irregularidade no tratamento de dados, quem será responsabilizado?</h4>
                <p>Tanto o Controlador como o Operador têm a obrigação de adotar as medidas
                  preventivas necessárias para a proteção dos dados pessoais em razão das
                  operações realizadas.
                </p>
                <p>Em casos onde o tratamento de dados recebidos não ocorra conforme previsto,
                  o Operador responderá solidariamente, a medida da sua responsabilidade,
                  caso não tenha cumprido as questões contratuais ou falhe em garantir
                  a segurança dos dados que recebeu.
                </p>

                <h4 className="text-dark font-bold mt-5">Nossa Governança para tratamento de dados pessoais</h4>
                <p>Temos um programa de governança e privacidade, regras de boas práticas e
                  de governança, políticas e procedimentos internos que estabelecem as
                  condições de organização, regime de funcionamento, mecanismos de
                  supervisão, de mitigação de riscos e outros aspectos relacionados ao
                  tratamento de dados pessoais.
                </p>
                <p>Promovemos treinamento e ações educativas aos colaboradores, bem como,
                  buscamos avaliar cuidadosamente nossos Fornecedores e firmar com eles
                  obrigações contratuais de segurança da informação e proteção de dados
                  pessoais, com o objetivo de minimizar riscos.
                </p>

                <h4 className="text-dark font-bold mt-5">O que é compartilhamento de dados pessoais?</h4>
                <p>Segundo a LGPD, o uso compartilhado pode ser entendido como as situações
                  em que os dados pessoais são comunicados, difundidos, transferidos
                  internacionalmente ou interconectados.
                </p>

                <h4 className="text-dark font-bold mt-5">Com quem compartilhamos os dados?</h4>
                <p>Os dados pessoais de clientes e outros titulares, que eventualmente sejam
                  armazenados no momento de coleta de evidências para os serviços
                  de segurança contratados, somente são compartilhados com o Cliente.
                </p>

                <h4 className="text-dark font-bold mt-5">Por quanto tempo armazenamos informações pessoais?</h4>
                <p>Armazenamos as informações dos usuários de acordo com as normas de
                  prescrição do Direito brasileiro.
                </p>
                <p>Os dados pessoais serão armazenados por nós de acordo com as leis de
                  proteção de dados aplicáveis, na medida do necessário para os fins
                  de processamento definidos em contrato com o Cliente.
                </p>
                <p>Posteriormente, após o prazo definido em contrato, excluiremos os
                  dados pessoais ou tomaremos medidas para tornar os dados anônimos
                  de maneira adequada, a menos que sejamos legalmente obrigados a
                  manter seus dados pessoais por mais tempo (por exemplo, para fins
                  de conformidade legal, impostos, contabilidade ou auditoria).
                </p>
                <p>Cabe ressaltar que o Site Blindado não contabiliza o número
                  de acessos aos sites dos Clientes que possuem o selo Site Blindado.
                </p>

                <h4 className="text-dark font-bold mt-5">Direitos como titular dos dados</h4>
                <p>Você pode exercer seus direitos entrando em contato pelo e-mail <a className="font-bold color-primary text-decoration-none"
                                                                                      href="mailto:suporte@siteblindado.com.br" target="_blank"
                                                                                      rel="noreferrer">suporte@siteblindado.com.br</a></p>

                <h4 className="text-dark font-bold mt-5">Política de Privacidade</h4>
                <p>Quer ter acesso à Política de Privacidade vigente e entender melhor quais informações suas coletamos
                  e como as tratamos, acesse a nossa Política de Privacidade <a className="font-bold color-primary text-decoration-none" target="_blank" rel="noreferrer" href="/consumidor/politica-de-privacidade/">
                    aqui
                  </a>.
                </p>

                <h4 className="text-dark font-bold mt-5">Como mantemos a segurança dos Dados</h4>
                <p>Adotamos práticas alinhadas aos padrões técnicos e regulatórios
                  de mercado para segurança e privacidade de dados, com ações
                  abrangentes em tecnologia e nos processos organizacionais.
                </p>
                <p>Protegemos os dados pessoais por meio de medidas de
                  segurança técnicas e organizacionais para minimizar os riscos
                  associados à perda de dados, uso indevido, acesso não autorizado
                  e divulgação e alteração não autorizada.
                </p>
                <p>Para isso, adotamos controles, lógicos, físicos e administrativos que
                  garantem o nível de segurança, referentes a requisitos de
                  confidencialidade, integridade, disponibilidade, autenticidade e
                  privacidade dos dados custodiados, armazenados ou processados pela companhia.
                </p>

                <ul>
                  <li>Medidas Técnicas</li>
                </ul>

                <p>Controle e monitoramento de perímetro físico e logico, canais de comunicação
                  de dados privados entre ambientes coorporativo e de data center, Proteção contra
                  aplicações e comportamentos maliciosos, identificação e análise de vulnerabilidades,
                  testes de controles de segurança, monitoramento, identificação, registro e
                  tratamento de eventos e incidentes de segurança, controle e gerenciamento de
                  identidade e acessos, registro e armazenamento de atividades executadas nos
                  ambientes lógicos e físicos, cópias de segurança e descarte seguro de informações.
                </p>

                <ul>
                  <li>Medidas Administativas</li>
                </ul>
                <p>Acordos de não divulgação e de confidencialidade, Código de Ética e Conduta,
                  análise de critérios antes da contratação de funcionários e terceiros, acordos
                  de tratamento de dados, avaliação de fornecedores e definição de Acordos de
                  Nível de Serviço. Processos relacionados à gestão de incidentes, gestão de riscos,
                  gestão de vulnerabilidades, gestão de ativos, gestão de mudanças, gestão de acessos
                  e desenvolvimento seguro. Programa de conscientização, desenvolvimento e capacitação
                  em segurança da informação e Políticas de Segurança da Informação, Classificação da
                  Informação, Controle de Acesso e Incidentes de Segurança.
                </p>
                <p>Todos esses controles são continuamente monitorados e revisados para acompanhar e reagir
                  ao contexto de ameaças inerentes aos nossos serviços, entretanto, não é possível
                  garantir que os nossos serviços sejam completamente invioláveis. Porém,
                  contamos com equipes dedicadas e capacitadas para detectar e responder prontamente,
                  no caso da ocorrência de algum evento ou incidente que comprometa a
                  segurança dos seus dados ou de nossos serviços.
                </p>
              </Col>
            </Row>
            <Row className="padding-row">
              <BlogNews />
            </Row>
            <Row className="padding-row">
              <Col xs={12} md={12}>
                <FormSB color="white" type="form-client" source="Página de FAQ" parentCallback={this.callbackFunction} title="Quero Ser Blindado"
                        description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span
                          className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}
